import { composeUrlWithParams, parseUrlParams } from '../utils.ts';

export type RouteParams =
  | { reportType?: never }
  | { reportType: 'policy-arrears' | 'salesperson-register-member'; salesCodes?: string[] };

const parser = ({ pathname, href }: Pick<Location, 'pathname' | 'href'>): null | RouteParams => {
  const match = pathname.match(/^\/reports(?:\/|\/([a-z\-\d]+)\/?)?$/i);

  if (!match) {
    return null;
  }

  if (match && !match[1]) {
    return {};
  }

  const reportType = match[1];
  const searchParams = parseUrlParams(new URL(href).search);

  switch (reportType) {
    case 'policy-arrears':
    case 'salesperson-register-member': {
      const { salesCodes } = searchParams;
      return {
        reportType,
        ...(salesCodes && Array.isArray(salesCodes) ? { salesCodes: salesCodes.map((s) => `${s}`) } : {}),
      };
    }
    default:
      return null;
  }
};

const composer = (params?: RouteParams): string => {
  switch (params?.reportType) {
    case 'policy-arrears':
    case 'salesperson-register-member': {
      const { reportType, salesCodes } = params;
      return composeUrlWithParams(`/reports/${reportType}`, {
        ...(salesCodes?.length ? { salesCodes } : {}),
      });
    }
    default:
      return `/reports`;
  }
};

export default {
  parser,
  composer,
};
