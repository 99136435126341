export type RouteParams = {};

/**
 * Regex use for matching the route.
 */
const routeRegex = /\/apply-online(\/?)$/;

/**
 * A function which can be used to parse the route associated with this microfrontend.
 * The parser should return null, if the route does not match. An empty object if the route
 * matches but no parameters are required and an object containing the parameters if they are present.
 */
const parser = ({ pathname }: Pick<Location, 'pathname'>): null | RouteParams => {
  const match = pathname.match(routeRegex);

  if (!match) {
    return null;
  }

  return {};
};

/**
 * A function which can be used to compose the routes associated with this microfrontend.
 * This function should accept an parameters and return a composed route. If parameters are
 * optional, this function should accomodate that fact.
 */
const composer = (): string => {
  return `/apply-online`;
};

export default {
  parser,
  composer,
};
