import adviserDetails from './routes/adviser-details';
import applyOnline from './routes/apply-online';
import auth from './routes/auth.ts';
import communications, { RouteParams as CommunicationsRouteParams } from './routes/communications';
import communicationSettings from './routes/communicationSettings';
import dashboard from './routes/dashboard';
import faq from './routes/faq.ts';
import groupDetails from './routes/group-details';
import groups, { RouteParams as GroupRouteParams } from './routes/groups';
import landing from './routes/landing';
import legalAgreements from './routes/legal-agreements.ts';
import migrateMe from './routes/migrate-me.ts';
import news from './routes/news';
import newsArticle from './routes/news-article';
import policies, { RouteParams as PoliciesRouteParams } from './routes/policies';
import policyDetails from './routes/policy-details';
import referenceMaterials from './routes/reference-materials';
import reports, { RouteParams as ReportsRouteParams } from './routes/reports.ts';
import salesTools from './routes/sales-tools';

export namespace RouteParams {
  export type Policies = PoliciesRouteParams;
  export type Groups = GroupRouteParams;
  export type Communications = CommunicationsRouteParams;
  export type Reports = ReportsRouteParams;
}

/*
  The reason this is manually constructed like this is so that the types are correctly defined.
  If you can come up with a way to dynamically generate these objects whilst maintining the type
  definitions then please update the code below.
*/

export const parsers = {
  adviserDetails: adviserDetails.parser,
  policies: policies.parser,
  policyDetails: policyDetails.parser,
  groups: groups.parser,
  groupDetails: groupDetails.parser,
  dashboard: dashboard.parser,
  landing: landing.parser,
  auth: auth.parser,
  migrateMe: migrateMe.parser,
  faq: faq.parser,
  legalAgreements: legalAgreements.parser,
  communications: communications.parser,
  communicationSettings: communicationSettings.parser,
  news: news.parser,
  reports: reports.parser,
  newsArticle: newsArticle.parser,
  salesTools: salesTools.parser,
  referenceMaterials: referenceMaterials.parser,
  applyOnline: applyOnline.parser,
} as const;

export type Parsers = typeof parsers;

export const composers = {
  adviserDetails: adviserDetails.composer,
  policies: policies.composer,
  policyDetails: policyDetails.composer,
  groups: groups.composer,
  groupDetails: groupDetails.composer,
  dashboard: dashboard.composer,
  landing: landing.composer,
  auth: auth.composer,
  migrateMe: migrateMe.composer,
  faq: faq.composer,
  legalAgreements: legalAgreements.composer,
  communications: communications.composer,
  communicationSettings: communicationSettings.composer,
  news: news.composer,
  reports: reports.composer,
  newsArticle: newsArticle.composer,
  salesTools: salesTools.composer,
  referenceMaterials: referenceMaterials.composer,
  applyOnline: applyOnline.composer,
} as const;

export type Composers = typeof composers;

const useUrlManager = () => {
  return {
    composers: {
      ...composers,
    },
  };
};

export default useUrlManager;
