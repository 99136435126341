import _ from 'lodash';
import { composeUrlWithParams, parseUrlParams, pickAndCast } from '../utils.ts';

type RoutePaginationParams = Partial<Record<'pageNumber', number>>;

export type RouteParams = {} & RoutePaginationParams;

const parser = ({ pathname, href }: Pick<Location, 'pathname' | 'href'>): null | RouteParams => {
  const match = pathname.match(/^\/news(?:\/?)$/);

  if (!match) {
    return null;
  }

  const searchParams = parseUrlParams(new URL(href).search);
  const pagination = {
    ...pickAndCast(searchParams, ['pageNumber'], (v) => +v),
  };

  return {
    ...pagination,
  };
};

const composer = (params?: RouteParams): string => {
  return composeUrlWithParams('/news/', {
    ..._.pick(params as Record<string, string>, ['pageNumber']),
  });
};

export default {
  parser,
  composer,
};
